/* eslint-disable @typescript-eslint/no-unused-vars */
import localForage from 'localforage';

export const name = 'diallink-v2.0.0-beta';
export let driver = localForage.INDEXEDDB;

if (import.meta.env.SSR) {
  const SSRSTORAGE = 'ssrStorage';

  await localForage.defineDriver({
    // ref: https://localforage.github.io/localForage/#driver-api-definedriver
    _driver: SSRSTORAGE,
    _support: true,
    _initStorage(options) {
      // Custom implementation here...
    },
    clear() {
      // Custom implementation here...
    },
    getItem(key) {
      // Custom implementation here...
    },
    iterate(iteratorCallback) {
      // Custom implementation here...
    },
    key(n) {
      // Custom implementation here...
    },
    keys(callback) {
      // Custom implementation here...
    },
    length(callback) {
      // Custom implementation here...
    },
    async removeItem(key) {
      // Custom implementation here...
    },
    async setItem(key, value) {
      // Custom implementation here...
    }
  } as LocalForageDriver);

  driver = SSRSTORAGE;
} else {
  localForage.ready(() => {
    localForage.dropInstance({ name, storeName: 'user' });
  });

  // Request persistent storage for site
  if (navigator.storage && navigator.storage.persist) {
    const isPersisted = await navigator.storage.persist();
    console.log(`Persisted storage granted: ${isPersisted}`);
  }
}

export default {
  route: localForage.createInstance({
    driver,
    name,
    storeName: 'route'
  }),
  address: localForage.createInstance({
    driver,
    name,
    storeName: 'address'
  }),
  audioLibrary: localForage.createInstance({
    name,
    storeName: 'audio-library'
  }),
  autoReceptionist: localForage.createInstance({
    driver,
    name,
    storeName: 'auto-receptionist'
  }),
  callQueue: localForage.createInstance({
    driver,
    name,
    storeName: 'call-queue'
  }),
  call: localForage.createInstance({
    driver,
    name,
    storeName: 'call'
  }),
  chat: localForage.createInstance({
    driver,
    name,
    storeName: 'chat'
  }),
  contact: localForage.createInstance({
    driver,
    name,
    storeName: 'contact'
  }),
  cnam: localForage.createInstance({
    driver,
    name,
    storeName: 'cnam'
  }),
  dictionary: localForage.createInstance({
    driver,
    name,
    storeName: 'dictionary'
  }),
  notification: localForage.createInstance({
    driver,
    name,
    storeName: 'notification'
  }),
  phoneNumber: localForage.createInstance({
    driver,
    name,
    storeName: 'phone-number'
  }),
  hubUser: localForage.createInstance({
    driver,
    name,
    storeName: 'hub-user'
  }),
  talkUser: localForage.createInstance({
    driver,
    name,
    storeName: 'talk-user'
  }),
  voicemail: localForage.createInstance({
    driver,
    name,
    storeName: 'voicemail'
  })
};
